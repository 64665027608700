import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import de from 'react-phone-input-2/lang/de.json';

import { ProfileRootState, removeEmptyStrings } from 'utils';
import { CloseIcon } from 'components/commonComponents/icons';
import { IContactPopupProps } from 'components/objectDetails/interface/objectDetails';
import { notify } from 'components/commonComponents/notification/notification';
import { getProfilDetails } from 'actions/searchActions';
import { userRole } from 'components/profile/utils/constants';
import { UserProfileFormData } from 'components/profile/utils/form.interface';
import CustomSpinner from 'components/commonComponents/customSpinner';
import { getGender } from 'utils';

import { API_CONFIG, HttpService } from 'services';

import 'react-phone-input-2/lib/material.css';
import {
	emailFieldRegex,
	nameFieldRegex,
	postalCodeRegex,
	textFieldRegex
} from 'components/newMyProperty/utils/constants';

const ContactPopup = (props: IContactPopupProps) => {
	const dispatch = useDispatch();
	const toolTipRef: any = useRef();
	const profileDetail = useSelector((state: ProfileRootState) => state.profileDetailReducer);
	const [loading, setLoading] = useState(false);
	const [, /* offsetWidth */ setOffsetWidth] = useState(0);
	const [acceptPolicy, setAcceptPolicy] = useState(false);
	const sellerDetail = (data: any) => {
		return {
			provider_company_name:
				(data.firstName || data.lastName) && `${getGender(data.gender)} ${data.firstName} ${data.lastName}`,
			street: data.street && data.street,
			phone: data.phone && data.phone,
			postal_code: data.postalCode && data.postalCode,
			realtor_city: data.city && data.city
		};
	};

	const realtorDetails = useMemo(() => {
		if (props.propertyDetail.realtor) {
			return props.propertyDetail.realtor;
		} else if (props.propertyDetail.user) {
			return sellerDetail(props.propertyDetail.user);
		}
	}, [props.propertyDetail]);

	const token = localStorage.getItem('token');

	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
		control
	} = useForm();

	useEffect(() => {
		if (token) {
			dispatch(getProfilDetails());
		}
	}, []); // eslint-disable-line

	useEffect(() => {
		toolTipRef && toolTipRef.current && setOffsetWidth(-(toolTipRef.current.offsetWidth / 2));
	}, [toolTipRef && toolTipRef.current]); // eslint-disable-line

	useEffect(() => {
		if (profileDetail && profileDetail.status === 'success' && profileDetail.data) {
			const profileData = profileDetail.data;
			reset({
				gender: profileData.gender,
				firstName: profileData.firstName,
				lastName: profileData.lastName,
				phone: profileData.phone,
				email: profileData.email,
				street: profileData.street,
				postalCode: profileData.postalCode,
				city: profileData.city
			});
		}
	}, [profileDetail]);

	const onSubmit = (data: UserProfileFormData) => {
		setLoading(true);
		if (token) {
			const profileData = profileDetail.data;
			let detail = {};
			detail = {
				gender: data.gender || profileData.gender,
				firstName: data.firstName || profileData.firstName,
				lastName: data.lastName || profileData.lastName,
				contactEmail: data.email || profileData.email,
				phone: data.phone || profileData.phone,
				street: data.street || profileData.street,
				postalCode: parseInt(data.postalCode) || profileData.postalCode,
				city: data.city || profileData.city,
				land: data.land || profileData.land,
				moreInformation: data.moreInformation || profileData.moreInformation
			};
			if (profileData.roleName === userRole.professionalBroker) {
				detail = {
					...detail,
					privacyPolicy: data.privacyPolicy || profileData.privacyPolicy,
					companyName: data.companyName || profileData.companyName,
					imprint: data.imprint || profileData.imprint,
					taxIdentifier: data.taxIdentifier || profileData.taxIdentifier
				};
			}
			let userRollURL = API_CONFIG.path.userBroker;
			if (profileData.roleName === userRole.propertySeeker) {
				userRollURL = API_CONFIG.path.userSeeker;
			} else if (profileData.roleName === userRole.propertyOwner) {
				userRollURL = API_CONFIG.path.userOwner;
			}
			const userDetail = removeEmptyStrings(detail);
			HttpService.put(userRollURL, { ...userDetail, objectId: props.objectId })
				.then((response) => {
					notify(`Ihre Anfrage wurde erfolgreich versendet`, 'success');
					setLoading(false);
					props.closeContactPopup();
				})
				.catch((err) => {
					notify(`${err.response.data.message}`, 'error');
					setLoading(false);
				});
		} else {
			const detail = {
				gender: data.gender,
				firstName: data.firstName,
				lastName: data.lastName,
				contactEmail: data.email,
				phone: data.phone,
				street: data.street,
				postalCode: parseInt(data.postalCode),
				city: data.city
			};
			HttpService.post(API_CONFIG.path.guestContactPopup, { ...detail, objectId: props.objectId })
				.then((response) => {
					setLoading(false);
					props.closeContactPopup();
					notify(`Ihre Anfrage wurde erfolgreich versendet`, 'success');
				})
				.catch((err) => {
					notify(`${err.response.data.message}`, 'error');
					setLoading(false);
				});
		}
	};
	console.log('realtorDetails:', realtorDetails);

	return (
		<div className={`image-gallery-popup carousel contact-popup `}>
			<div className='close-btn-div' onClick={() => props.closeContactPopup()}>
				<CloseIcon />
			</div>
			<div className='contact-popup-wrapper'>
				<form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
					<h2 className='contact-popup-tittle'>Ihre Kontaktanfrage an:</h2>
					{realtorDetails && (
						<div>
							<div className='mb-2 realtor-name d-flex justify-content-start align-items-stretch mt-2'>
								<span className='contact-popup-name'>{realtorDetails.provider_company_name}</span>
								{/* <div className='info-btn cursor-pointer ml-2'>
									<div className='tool-tip' ref={toolTipRef} style={{ right: offsetWidth }}>
										<p>
											{realtorDetails.street}.&nbsp;{realtorDetails.phone}.&nbsp;
											{realtorDetails.postal_code}.&nbsp;
											{realtorDetails.realtor_city}
										</p>
									</div>
									<ExclamationMarkFilled />
								</div> */}
							</div>
						</div>
					)}
					<div className='contactPopup-form'>
						<div className='contactPopup-form-input-wrapper'>
							<label className='contactPopup-form-label'>Anrede *</label>
							<div className='flex'>
								<div className='flex'>
									<input
										className={`input-redio-btn cursor-pointer`}
										{...register('gender', { required: true })}
										type='radio'
										value='Male'
										id='field-wind'
										style={{
											border: `${errors.gender?.type ? '1px solid red' : '1px solid #d9d9d9'} `,
											borderRadius: '100%'
										}}
									/>
									<label className='field-wind mr-4'>Herr</label>
								</div>
								<div className='flex '>
									<input
										className={`input-redio-btn cursor-pointer`}
										{...register('gender', { required: true })}
										type='radio'
										value='Female'
										id='field'
										style={{
											border: `${errors?.gender?.type ? '1px solid red' : ' 1px solid #d9d9d9'} `,
											borderRadius: '100%'
										}}
									/>
									<label className='field-wind'>Frau</label>
								</div>
							</div>
						</div>
						<div className='flex full-width contactPopup-form-input-wrapper'>
							<div className='w--50 mr-2'>
								<label className='contactPopup-form-label'>Vorname *</label>
								<input
									className={`contactPopup-form-input ${errors.firstName?.type && 'error-border'}`}
									{...register('firstName', {
										required: true,
										// eslint-disable-next-line no-use-before-define
										pattern: nameFieldRegex
									})}
									type='text'
									style={{ boxShadow: 'none' }}
								/>
							</div>
							<div className='w--50 ml-2'>
								<label className='contactPopup-form-label'>Nachname *</label>
								<input
									className={`contactPopup-form-input ${errors.lastName?.type && 'error-border'}`}
									{...register('lastName', {
										required: true,
										// eslint-disable-next-line no-use-before-define
										pattern: nameFieldRegex
									})}
									type='text'
									style={{ boxShadow: 'none' }}
								/>
							</div>
						</div>
						<div className='contactPopup-form-input-wrapper'>
							<label className='contactPopup-form-label'>Telefon *</label>
							<Controller
								control={control}
								name='phone'
								rules={{
									required: true,
									minLength: { value: 10, message: 'Bitte geben Sie eine gültige Nummer ein' }
								}}
								render={({ field: { ref, value, ...field } }) => (
									<PhoneInput
										{...field}
										isValid={true}
										country={'de'}
										localization={de}
										specialLabel={''}
										value={value}
										inputProps={{
											name: 'phone',
											required: true
										}}
										containerStyle={
											errors.phone?.type && {
												border: '1px solid red',
												borderRadius: '5px'
											}
										}
									/>
								)}
							/>
						</div>
						<div className='contactPopup-form-input-wrapper'>
							<label className='contactPopup-form-label'>E-Mail-Adresse *</label>
							<input
								className={`contactPopup-form-input ${errors.email?.type && 'error-border'} ${
									token ? 'not-allowed' : ''
								}`}
								{...register('email', {
									required: true,
									pattern: emailFieldRegex // eslint-disable-line
								})}
								type='text'
								style={{ boxShadow: 'none' }}
								readOnly={!!token}
							/>
						</div>
						<div className='contactPopup-form-input-wrapper'>
							<label className='contactPopup-form-label'>Straße und Hausnummer *</label>
							<input
								className={`contactPopup-form-input ${errors.street?.type && 'error-border'}`}
								{...register('street', {
									required: true
									// pattern: /^[a-zA-Z0-9\s,'-]*$/, // eslint-disable-line
								})}
								type='text'
								style={{ boxShadow: 'none' }}
							/>
						</div>
						<div className='flex full-width contactPopup-form-input-wrapper'>
							<div className='w--50 mr-2'>
								<label className='contactPopup-form-label'>Postleitzahl *</label>
								<input
									className={`contactPopup-form-input ${errors.postalCode?.type && 'error-border'}`}
									{...register('postalCode', {
										required: true,
										pattern: postalCodeRegex // eslint-disable-line
									})}
									type='number'
									style={{ boxShadow: 'none' }}
								/>
							</div>
							<div className='w--50 ml-2'>
								<label className='contactPopup-form-label'>Stadt *</label>
								<input
									className={`contactPopup-form-input ${errors.city?.type && 'error-border'}`}
									{...register('city', {
										required: true,
										// eslint-disable-next-line no-use-before-define
										pattern: textFieldRegex
									})}
									type='text'
									style={{ boxShadow: 'none' }}
								/>
							</div>
						</div>
						<div className='confirm-wrapper contectPopup-check-box'>
							<label className='confirm-container'>
								<p>
									Mit Absenden der Anfrage nimmst du den Immoweb-Service in Anspruch. Du erhältst
									anhand der von dir eingegebenen Daten, genutzten Services und auf Grundlage unseres
									Geschäftszwecks auf dein Anliegen ausgerichtete Informationen. Diesem Service kannst
									du jederzeit unter&nbsp;
									<a href='mailto:datenschutz@immoweb.de'>datenschutz@immoweb.de</a> widersprechen.
									Weitere Informationen findest du in der Datenschutzerklärung.
								</p>
								<input type='checkbox' onChange={() => setAcceptPolicy(!acceptPolicy)} />
								<span className='checkmark'></span>
							</label>
						</div>
						<div className='error-massage'>
							{Object.keys(errors).length > 0 && <p>*Bitte füllen Sie dieses Feld aus</p>}
						</div>
						<div className='m-t-15 text--center'>
							<button
								className={`common-button cursor-pointer contactPopup-button ${
									!acceptPolicy ? 'disable' : ''
								}`}
								type='submit'
								disabled={!acceptPolicy}
							>
								{loading ? (
									<CustomSpinner className='spinner-border-sm' animation='border' />
								) : (
									<>Anfrage senden</>
								)}
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ContactPopup;
