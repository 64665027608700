import { ThunkDispatch } from 'redux-thunk';
import { IAction } from '../shared/state';
import { HttpService } from 'services';
const token = localStorage.getItem('token') || '';

/**
 * create action creator
 * @param ACTION - type of action
 * @param data - data
 */
export const createAction = (ACTION: string, data: any = null): IAction => ({
	type: ACTION,
	payload: data
});

/**
 * dispatch action after given time (to handle some events like close modal after success api call)
 * @param dispatch - dispatch object
 * @param action - action type
 * @param time - time after which action is to be dispatched (default - 100ms)
 */
export const dispatchActionAfterTime = (
	dispatch: ThunkDispatch<unknown, unknown, IAction>,
	action: string,
	time = 100
) => {
	setTimeout(() => {
		dispatch(createAction(action));
	}, time);
};

export const getAuthToken = (): string | null => token;

export const handleOutSideClickOfModal = (e: any, closeModal: () => void) => {
	var container: any = document.getElementById('modal-content');
	if (!!container && !container.contains(e.target)) {
		container.parentNode.classList.add('fade-out');
		setTimeout(() => {
			container.parentNode.classList.remove('fade-out');
			closeModal();
		}, 1000);
	}
};

const isObject = (item: any) => {
	return item && typeof item === 'object' && !Array.isArray(item);
};

export const mergeDeep = (target: any, ...sources: any): any => {
	if (!sources.length) return target;
	const source = sources.shift();

	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key])
					Object.assign(target, {
						[key]: {}
					});
				mergeDeep(target[key], source[key]);
			} else {
				Object.assign(target, {
					[key]: source[key]
				});
			}
		}
	}

	return mergeDeep(target, ...sources);
};

export const openInNewTab = (url: string) => {
	const link = document.createElement('a');
	link.href = url;
	link.target = '_blank';
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const downloadFile = async (url: string, fileType: string = 'application/pdf') => {
	try {
		const pdfData = await HttpService.get(url, {}, { responseType: 'blob' });
		console.log('pdfData:', pdfData);
		const file = new Blob([pdfData], { type: fileType });
		const data = window.URL.createObjectURL(file);
		console.log('data:', data);
		const link = document.createElement('a');
		link.href = data;
		link.download = 'file.pdf'; // Set the default file name for the download
		document.body.appendChild(link); // Append the link to the body
		link.click();
		document.body.removeChild(link); // Remove the link after clicking
	} catch (err) {
		console.error(err);
	}
};

export const getGender = (gender: string) => {
	if (gender === 'Male') {
		return 'Herr';
	} else {
		return 'Frau';
	}
};
