import React from 'react';
import * as objectFunctions from '../utils/objectFunctions';
import MapWithAMarker from 'components/objectDetails/components/mapForObject';
// import getLocalizeText from 'utils/getLocalizeText';
// import englishText from 'languages/english';
import * as objectLocaInterface from '../interface/objectDetails';

function ObjectLocation(props: objectLocaInterface.IObjectLocProps) {
	const { state, setStateForObject } = props;
	const { propertyDetail } = state;

	const localSupplyArray =
		propertyDetail && propertyDetail.closestDistance && objectFunctions.getLocalSupplyArray(propertyDetail);

	const localInfoArray = propertyDetail && objectFunctions.getLocalInfoArray(propertyDetail);

	return (
		<div className='row mt-4 mb-5'>
			<div className='col-md-8 col-sm-7 map-location'>
				<p className='label-text'>Lage</p>
				<div className='map-view'>
					{propertyDetail.location && propertyDetail.location.lat ? (
						<MapWithAMarker
							loadingElement={<div style={{ height: `100%` }} />}
							containerElement={<div style={{ height: `100%` }} />}
							mapElement={<div style={{ height: `100%` }} />}
							lat={parseFloat(propertyDetail.location.lat)}
							lon={parseFloat(propertyDetail.location.lon)}
							title={propertyDetail.objectTitle}
							score={propertyDetail.score}
							fromPopup={false}
							onMarkerClick={() => {
								setStateForObject('showMapPopup', true);
							}}
						/>
					) : (
						<div className='empty-map'></div>
					)}
				</div>
			</div>
			{propertyDetail.showAddress && (
				<div className='col-md-4 col-sm-5 location-info'>
					<p className='label-text'>Objektadresse:</p>
					<div className='detail-item'>
						<p className='property-address'>{propertyDetail.adrRaw ?? ''}</p>
						<p className='property-address'>
							{propertyDetail.adrPostalCode ?? ''} {propertyDetail.adrCity ?? ''}
						</p>
					</div>
				</div>
			)}
			{/* <div className='col-md-4 col-sm-5 location-info'>
					<h2 className='title mb-3 mt-4'>{getLocalizeText(englishText.LOCATION_INFORMATION)}</h2>

					{localInfoArray &&
						localInfoArray.map((info: any, index: number) => (
							<div className='detail-item d-flex align-items-center' key={index}>
								<p className='w-50'>{getLocalizeText(info.label)}</p>
								<p className='w-50'>{info.value}</p>
							</div>
						))}

					<h2 className='title mb-4 mt-4'>{getLocalizeText(englishText.LOCAL_SUPPLY)}</h2>
					{localSupplyArray &&
						localSupplyArray.map((local: any, index: number) => (
							<div className='detail-item d-flex align-items-center' key={index}>
								<p className='w-50'>{getLocalizeText(local.label)}</p>
								<p className='w-50'>{local.value}</p>
							</div>
						))}
				</div> */}
		</div>
	);
}

export default ObjectLocation;
